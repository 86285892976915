<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.seller')}}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{$t('seller.breadcrumb.marketPlatform')}}
				</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'singletreasureList' }">{{$t('seller.breadcrumb.singletreasure')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="seller-panel">
                <div class="panel-steps">
					<el-steps :active="stepActive" align-center>
						<el-step v-for="item in $t('seller.singletreasure.stepsOption')" :key="item" :title="item"></el-step>
					</el-steps>
                </div>
                <el-descriptions class="panel-hd">
                    <el-descriptions-item :label="$t('seller.screen.campaignId')">{{ pageInfo.single_treasure_id }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="panel-bd">
                    <el-descriptions-item :label="$t('seller.screen.campaignTag')">{{ pageInfo.label_name }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.campaign.activityTime')">{{ pageInfo.start_time | parseTime() }} 至 {{ pageInfo.end_time | parseTime() }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.createTime')">{{ pageInfo.create_time | parseTime() }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.couponLevel')">{{ pageInfo.dis_level | typeFilter($t('seller.filters.discountLevel')) }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.formItem.preferentialWay')">{{ pageInfo.dis_type | typeFilter($t('seller.filters.discountType')) }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.campaignName')">{{ pageInfo.name }}</el-descriptions-item>
                </el-descriptions>
				<div class="panel-title">
					<span>{{ $t('seller.singletreasure.setGoodsInfoText') }}</span>
					<router-link :to="{ name: 'singletreasureAddItem', params: $route.params }">
						<el-button type="primary" size="mini">{{ $t('seller.actions.addGoods') }}</el-button>
					</router-link>
				</div>
				<el-table v-loading="listLoading" :data="pageList" ref="goodsTable" class="seller-table">
					<el-table-column type="selection" width="55px"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.babyDescribe')">
						<template slot-scope="scope">
							<div class="goods-item">
								<a :href="$itemBasePath+'/item/'+scope.row.goods_id" class="item-pic" target="_blank"><img :src="scope.row.default_image" @error="goodsLoadError" alt=""></a>
								<div class="item-main">
									<div class="name"><a :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a></div>
									<div class="text">ID：{{ scope.row.goods_id }}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.unitPrice')" prop="price" align="center">
						<template slot-scope="scope">
							￥{{ scope.row.price }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.stock')" prop="stock" align="center"></el-table-column>
					<el-table-column :label="$t('seller.formItem.preferentialWay')" align="center">
						<template slot-scope="scope">
							<template v-if="pageInfo.dis_level === 1">
								<p v-if="pageInfo.dis_type === 1">
									{{$t('seller.singletreasure.discount1')}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleGoodsDiscount($event, scope)" @change="handleUpdateRow($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.singletreasure.discount2')}}
								</p>
								<p v-else-if="pageInfo.dis_type === 2">
									{{$t('seller.singletreasure.reduce')}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleGoodsDiscount($event, scope)" @change="handleUpdateRow($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}
								</p>
								<p v-else-if="pageInfo.dis_type === 3">
									{{$t('seller.singletreasure.salePrice')+'：'}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleGoodsDiscount($event, scope)" @change="handleUpdateRow($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin-left: 5px;"></el-input>
								</p>
								<p v-if="errorGoods[scope.row.single_treasure_goods_id] === 1" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText1')}}</p>
								<p v-else-if="errorGoods[scope.row.single_treasure_goods_id] === 2" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText2')}}</p>
								<p v-else-if="errorGoods[scope.row.single_treasure_goods_id] === 3" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText3')}}{{ scope.row.price }}{{$t('seller.singletreasure.errorText4')}}
								</p>
								<p v-else-if="errorGoods[scope.row.single_treasure_goods_id] === 4" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText5')}}</p>
								<p v-else-if="errorGoods[scope.row.single_treasure_goods_id] === 5" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText6')}}</p>
							</template>
							<template v-else-if="pageInfo.dis_level === 2">
								<p>{{ pageInfo.dis_type | typeFilter($t('seller.filters.discountType')) }}</p>
								<p>
									<el-button type="text" @click="handleDialogVisibleSku(scope)">SKU{{ $t('seller.actions.couponSet')}}</el-button>
								</p>
							</template>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.priceAfterDiscount')" align="center">
						<template slot-scope="scope">
							<p>{{ priceRange(scope) }}</p>
							<p>
								<el-button type="text" v-if="scope.row.erase_status !== 1" @click="handleGoodsErasure(scope,1)">{{ $t('seller.actions.round')}}</el-button>
								<el-button type="text" v-else @click="handleGoodsErasure(scope,0)">{{ $t('seller.actions.unround')}}</el-button>
								<el-button type="text" v-if="scope.row.erase_status !== 2" @click="handleGoodsErasure(scope,2)">{{ $t('seller.actions.erasure')}}</el-button>
								<el-button type="text" v-else @click="handleGoodsErasure(scope,0)">{{ $t('seller.actions.unerasure')}}</el-button>
							</p>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.perLimit')" v-if="pageInfo.dis_level === 1">
						<template slot-scope="scope">
							<el-select v-model="scope.row.limit" size="mini" @change="handleUpdateRow($event, scope)" style="width: 100px">
								<el-option :label="$t('seller.singletreasure.noLimit')" :value="0"></el-option>
								<el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.handle')" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="handleWithdrawal(scope)">{{ $t('seller.actions.withdrawalActivity')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="panel-bottom" v-if="pageTotal > 0">
					<el-button type="text" @click="handleBatchWithdrawal">{{ $t('seller.actions.batchWithdrawal') }}</el-button>
					<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
						@pagination="getList"></pagination>
				</div>
				<el-dialog :title="dialogTitleSku" :visible.sync="dialogVisibleSku" width="1200px" custom-class="seller-dialog">
					<el-scrollbar class="dialog-sku" v-if="postGoods">
						<div class="dialog-head">
							<div v-if="pageInfo.dis_type === 1">
								{{$t('seller.singletreasure.discount1')}}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.singletreasure.discount2')}}
							</div>
							<div v-else-if="pageInfo.dis_type === 2">
								{{$t('seller.singletreasure.reduce')}}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}
							</div>
							<div v-else-if="pageInfo.dis_type === 3">
								{{$t('seller.singletreasure.salePrice')+'：'}}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>
							</div>
							<div>
								<el-button type="text" v-if="postGoods.erase_status !== 1" @click="handleDialogErasure(1)">{{ $t('seller.actions.round')}}</el-button>
								<el-button type="text" v-else @click="handleDialogErasure(0)">{{ $t('seller.actions.unround')}}</el-button>
								<el-button type="text" v-if="postGoods.erase_status !== 2" @click="handleDialogErasure(2)">{{ $t('seller.actions.erasure')}}</el-button>
								<el-button type="text" v-else @click="handleDialogErasure(0)">{{ $t('seller.actions.unerasure')}}</el-button>
							</div>
						</div>
						<el-table :data="postGoods.spec_list" border>
							<el-table-column label="SKU_ID" prop="spec_id"></el-table-column>
							<el-table-column label="SKU">
								<template slot-scope="scope">
									<span style="margin-right: 10px;">{{ scope.row.spec_1 }}</span>
									<span>{{ scope.row.spec_2 }}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('seller.tableHead.price')" prop="origin_price"></el-table-column>
							<el-table-column :label="$t('seller.tableHead.discount')">
								<template slot-scope="scope">
									<p v-if="pageInfo.dis_type === 1">
										{{$t('seller.singletreasure.discount1')}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.singletreasure.discount2')}}
									</p>
									<p v-else-if="pageInfo.dis_type === 2">
										{{$t('seller.singletreasure.reduce')}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}
									</p>
									<p v-else-if="pageInfo.dis_type === 3">
										{{$t('seller.singletreasure.salePrice')+'：'}}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin-left: 5px;"></el-input>
									</p>
									<p v-if="errorGoods[scope.row.spec_id] === 1" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText1')}}</p>
									<p v-else-if="errorGoods[scope.row.spec_id] === 2" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText2')}}</p>
									<p v-else-if="errorGoods[scope.row.spec_id] === 3" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText3')}}{{ scope.row.origin_price }}{{$t('seller.singletreasure.errorText4')}}</p>
									<p v-else-if="errorGoods[scope.row.spec_id] === 4" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText5')}}</p>
									<p v-else-if="errorGoods[scope.row.spec_id] === 5" class="table-error"><i class="el-icon-circle-close"></i>{{$t('seller.singletreasure.errorText6')}}</p>
								</template>
							</el-table-column>
							<el-table-column :label="$t('seller.tableHead.discountPrice')" prop="dis_price"></el-table-column>
						</el-table>
					</el-scrollbar>
					<div slot="footer" class="dialog-footer">
						<el-button @click="dialogVisibleSku = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
						<el-button type="primary" @click="handleConfirmSku" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
					</div>
				</el-dialog>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchTreasuresInfo, fetchTreasuresItems, updateTreasuresGoods, deleteTreasuresGoods } from '@/api/seller/market'
export default {
	components: { Pagination },
	data() {
		return {
			stepActive: 2,
			pageInfo: {},
			listLoading: false,
			pageList: [],
            pageTotal: 0,
            pageCurr: 1,
            pageSize: 10,
			errorGoods: {},
			postIndex: -1,
			postGoods: {},
			dialogVisibleSku: false,
			dialogTitleSku: '',
			dialogDiscount: ''
		}
	},
	created() {
		this.getData()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		async getData() {
			try {
				const { data } = await fetchTreasuresInfo({ single_treasure_id: this.$route.params.id })
				this.pageInfo = data
				this.getList()
			} catch (error) {
				console.log(error)
			}
		},
		getList() {
			this.listLoading = true
			let param = {
				...this.screenForm,
				dis_level: this.pageInfo.dis_level,
				single_treasure_id: this.pageInfo.single_treasure_id,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchTreasuresItems(param).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		priceRange({ row }) {
            if (this.pageInfo.dis_level === 2) {
                var max = 0
                var min = 0
                row.spec_list.forEach(item => {
                    var price = item.dis_price || item.origin_price
                    if(max > 0) {
                        if(price > max) {
                            max = price
                        }
                    } else {
                        max = price
                    }
                    if(min > 0) {
                        if(price < min) {
                            min = price
                        }
                    } else {
                        min = price
                    }
                })
                if(max === min) {
                    return '￥'+max
                } else {
                    return '￥'+min+'~￥'+max
                }
            } else {
                return '￥'+ row.dis_price
            }
        },
		handleGoodsDiscount(val,{ $index, row }) {
			this.postIndex = $index
			this.postGoods = Object.assign({}, row)
			this.uploadGoodsPrice()
		},
		handleUpdateRow(val,{ row }) {
			var params = {
				...row,
				is_main_page: 1
			}
			params.spec_list = JSON.stringify(params.spec_list)
			updateTreasuresGoods(params).then(() => {
				this.getList()
			})
		},
		handleGoodsErasure({ $index, row },type) {
			this.postIndex = $index
			this.postGoods = Object.assign({}, row)
			this.postGoods.erase_status = type
            if (this.pageInfo.dis_level === 2) {
                this.dialogDiscount = ''
                this.uploadSkuAllPrice(0)
            } else {
                if (!this.errorGoods[row.single_treasure_goods_id] > 0 && this.postGoods.dis_value) {
                    this.uploadGoodsPrice()
                }
            }
			this.$nextTick(() => {
				this.handleUpdate()
			})
		},
		uploadGoodsPrice() {
            this.errorGoods[this.postGoods.single_treasure_goods_id] = 0
            switch (this.pageInfo.dis_type) {
                case 1:
					this.postGoods.dis_price = this.postGoods.origin_price * this.postGoods.dis_value / 10
                    if (this.postGoods.dis_value * 1 > 9.99 || this.postGoods.dis_value * 1 < 0.01) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 1
                    } else if (this.postGoods.dis_value * 1 < 7) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 4
                    }
                    break;
                case 2:
					this.postGoods.dis_price = this.postGoods.origin_price - this.postGoods.dis_value * 1
                    if (this.postGoods.dis_value * 1 > this.postGoods.origin_price) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 2
                    } else if (this.postGoods.dis_price / this.postGoods.origin_price < 0.7) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 4
                    }
                    break;
                case 3:
					this.postGoods.dis_price = this.postGoods.dis_value * 1
                    if (this.postGoods.dis_value * 1 > this.postGoods.origin_price || this.postGoods.dis_value * 1 < 0.01) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 3
                    } else if (this.postGoods.dis_price / this.postGoods.origin_price < 0.7) {
                        this.errorGoods[this.postGoods.single_treasure_goods_id] = 4
                    }
                    break;
            }
            if (this.postGoods.erase_status === 1) { // 取整
                this.postGoods.dis_price = parseInt(this.postGoods.dis_price)
            } else if (this.postGoods.erase_status === 2) { // 抹分
                this.postGoods.dis_price = parseFloat(this.postGoods.dis_price).toFixed(1)
            } else {
                this.postGoods.dis_price = parseFloat(this.postGoods.dis_price).toFixed(2)
            }
            this.$set(this.pageList,this.postIndex,this.postGoods)
		},
		handleDialogVisibleSku({ $index, row }) {
            const option = this.$t('seller.filters.discountType')
            this.dialogTitleSku = 'SKU' + option[this.pageInfo.dis_type] + '设置'
            this.dialogDiscount = ''
			this.postIndex = $index
			this.postGoods = Object.assign({}, row)
            this.dialogVisibleSku = true
		},
		handleSkuDiscountAll() {
			this.uploadSkuAllPrice(1)
		},
		handleDialogErasure(type) {
			this.postGoods.erase_status = type
			this.$forceUpdate()
			this.uploadSkuAllPrice(0)
		},
		handleSkuDiscount(val, { row }) {
			switch (this.pageInfo.dis_type) {
				case 1:
					row.dis_price = row.origin_price * row.dis_value / 10
					if (row.dis_value * 1 > 9.99 || row.dis_value * 1 < 0.01) {
						this.errorGoods[row.spec_id] = 1
					} else if (row.dis_value * 1 < 7) {
						this.errorGoods[row.spec_id] = 4
					}
					break;
				case 2:
					row.dis_price = row.origin_price * 1 - row.dis_value * 1
					if (row.dis_value * 1 > row.origin_price * 1) {
						this.errorGoods[row.spec_id] = 2
					} else if (row.dis_price / row.origin_price < 0.7) {
						this.errorGoods[row.spec_id] = 4
					}
					break;
				case 3:
					row.dis_price = row.dis_value * 1
					if (row.dis_value * 1 > row.origin_price * 1 || row.dis_value * 1 < 0.01) {
						this.errorGoods[row.spec_id] = 3
					} else if (row.dis_price / row.origin_price < 0.7) {
						this.errorGoods[row.spec_id] = 4
					}
					break;
			}
			if (this.postGoods.erase_status === 1) { // 取整
				row.dis_price = parseInt(row.dis_price)
			} else if (this.postGoods.erase_status === 2) { // 抹分
				row.dis_price = parseFloat(row.dis_price).toFixed(1)
			} else {
				row.dis_price = parseFloat(row.dis_price).toFixed(2)
			}
		},
		uploadSkuAllPrice(type) {
            this.postGoods.spec_list.forEach(item => {
                this.errorGoods[item.spec_id] = 0
                if (this.dialogDiscount && type===1) {
                    item.dis_value = this.dialogDiscount
                }
                switch (this.pageInfo.dis_type) {
                    case 1:
                        item.dis_price = item.origin_price * item.dis_value / 10
                        if (item.dis_value * 1 > 9.99 || item.dis_value * 1 < 0.01) {
                            this.errorGoods[item.spec_id] = 1
                        } else if (item.dis_value * 1 < 7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                    case 2:
                        item.dis_price = item.origin_price - item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price) {
                            this.errorGoods[item.spec_id] = 2
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                    case 3:
                        item.dis_price = item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price || item.dis_value * 1 < 0.01) {
                            this.errorGoods[item.spec_id] = 3
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                }
                if (this.postGoods.erase_status === 1) { // 取整
                    item.dis_price = parseInt(item.dis_price)
                } else if (this.postGoods.erase_status === 2) { // 抹分
                    item.dis_price = parseFloat(item.dis_price).toFixed(1)
                } else {
                    item.dis_price = parseFloat(item.dis_price).toFixed(2)
                }
            })
        },
		handleConfirmSku() {
			this.handleUpdate()
			this.dialogVisibleSku = false
		},
		handleUpdate() {
			var params = {
				...this.postGoods,
				is_main_page: 1
			}
			params.spec_list = JSON.stringify(params.spec_list)
			updateTreasuresGoods(params).then(() => {
				this.getList()
			})
		},
		handleWithdrawal({ row }) {
            this.$confirm(this.$t('seller.confirm.withdrawalActivityText'), this.$t('seller.actions.withdrawalActivity'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
				deleteTreasuresGoods({ single_treasure_goods_id: row.single_treasure_goods_id}).then(() => {
					this.getList()
				})
            }).catch(() => { })
		},
		handleBatchWithdrawal({ row }) {
			if(!this.$refs.goodsTable.selection.length > 0) {
				this.$message.error(this.$t('seller.errorMsg.ckeckItem'))
				return false
			}
            this.$confirm(this.$t('seller.confirm.batchWithdrawalActivity'), this.$t('seller.confirm.batchWithdrawalTitle'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
				var ids = []
				this.$refs.goodsTable.selection.forEach(item => {
					ids.push(item.single_treasure_goods_id)
				})
				deleteTreasuresGoods({ single_treasure_goods_id: ids.join(',')}).then(() => {
					this.getList()
				})
            }).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-panel {
	.panel-steps {
        padding: 20px 0;
        margin-bottom: 12px;
        border-bottom: 1px solid #e6e6e6;
    }

    .panel-hd {
        margin-bottom: 12px;
        border-bottom: 1px solid #e6e6e6;
    }

    .panel-bd {
        border-bottom: 1px solid #e6e6e6;
    }

	.panel-title {
		height: 42px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #666666;
		justify-content: space-between;
		// border-bottom: 1px solid #e6e6e6;
	}

	::v-deep .el-table {
		font-size: 12px;
	}

	.goods-item {
		display: flex;
		min-width: 200px;

		.item-pic {
			flex-shrink: 0;
			width: 52px;
			height: 52px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			flex: 1;
			width: 0;
			margin-left: 10px;

			.name {
				min-height: 32px;
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 5px;

				a {
					color: #5584ff;
				}
			}

			.text {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}
	.panel-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.el-button--text {
			margin-top: 15px;
		}
	}
}
.dialog-sku {
	max-height: 600px;
    .dialog-head {
        display: flex;
        margin-bottom: 12px;
		align-items: center;
        justify-content: space-between;
    }
}
</style>
